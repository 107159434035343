import React from "react";

const Subtitle = ({ ...others }) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M5.55556 5C2.47222 5 0 7.57143 0 10.7143V39.2857C0 42.4286 2.47222 45 5.55556 45H44.4444C47.5 45 50 42.4286 50 39.2857V10.7143C50 7.57143 47.5 5 44.4444 5H5.55556ZM22.2222 22.1429H18.0556V20.7143H12.5V29.2857H18.0556V27.8571H22.2222V30.7143C22.2222 32.2857 20.9722 33.5714 19.4444 33.5714H11.1111C9.58333 33.5714 8.33333 32.2857 8.33333 30.7143V19.2857C8.33333 17.7143 9.58333 16.4286 11.1111 16.4286H19.4444C20.9722 16.4286 22.2222 17.7143 22.2222 19.2857V22.1429ZM41.6667 22.1429H37.5V20.7143H31.9444V29.2857H37.5V27.8571H41.6667V30.7143C41.6667 32.2857 40.4167 33.5714 38.8889 33.5714H30.5556C29.0278 33.5714 27.7778 32.2857 27.7778 30.7143V19.2857C27.7778 17.7143 29.0278 16.4286 30.5556 16.4286H38.8889C40.4167 16.4286 41.6667 17.7143 41.6667 19.2857V22.1429Z"
        fill="white"
      />
    </svg>
  );
};

export default Subtitle;
