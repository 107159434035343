import React from "react";

const Cog = ({ ...others }) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M44.1242 27.4467C44.2208 26.6625 44.2852 25.8469 44.2852 25C44.2852 24.1531 44.2208 23.3689 44.1242 22.5533L49.5331 18.4128C50.016 18.0364 50.1448 17.3777 49.8228 16.8131L44.6715 8.15558C44.3818 7.62233 43.6735 7.40276 43.1262 7.62233L36.7192 10.1317C35.3992 9.12798 33.9504 8.31242 32.3728 7.68507L31.4069 1.03513C31.3425 0.439147 30.7952 0 30.1513 0H19.8487C19.2048 0 18.6897 0.439147 18.5931 1.03513L17.6272 7.68507C16.0818 8.31242 14.633 9.15935 13.2808 10.1317L6.87385 7.62233C6.29432 7.40276 5.61822 7.62233 5.32846 8.15558L0.17715 16.8131C-0.144806 17.3463 0.0161725 18.0364 0.466912 18.4128L5.87578 22.5533C5.7792 23.3375 5.7148 24.1844 5.7148 25C5.7148 25.8156 5.7792 26.6311 5.87578 27.4467L0.466912 31.5872C-0.0160232 31.9636 -0.144806 32.6223 0.17715 33.187L5.32846 41.8444C5.61822 42.3777 6.32652 42.5972 6.87385 42.3777L13.2808 39.8683C14.6008 40.872 16.0496 41.6876 17.6272 42.3149L18.5931 48.9649C18.6575 49.5609 19.2048 50 19.8487 50H30.1513C30.7952 50 31.3103 49.5609 31.4069 48.9649L32.3728 42.3149C33.9182 41.6876 35.367 40.8407 36.7192 39.8683L43.1262 42.3777C43.7057 42.5972 44.3818 42.3777 44.6715 41.8444L49.8228 33.187C50.1126 32.6537 49.9838 31.9636 49.5331 31.5872L44.1242 27.4467V27.4467ZM25 33.7516C20.0419 33.7516 15.9852 29.8306 15.9852 24.9686C15.9852 20.138 20.0097 16.1857 25 16.1857C29.9581 16.1857 34.0148 20.1067 34.0148 24.9686C34.0148 29.7992 29.9903 33.7516 25 33.7516Z"
        fill="white"
      />
    </svg>
  );
};

export default Cog;
